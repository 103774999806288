declare global {
  interface Window {
    rudderanalytics: {
      track: (eventName: string, options?: unknown) => void;
      identify: (userId: string, options: unknown) => void;
    };
  }
}

enum RudderEvents {
  APP_INSTALL = "App Install",
  CONTACT_FORM_SUBMIT = "Contact Us Form Submitted",
  PHONE_NUMBER_CLICKED = "Call Us Phone Number Clicked",
  SUPPORT_EMAIL_CLICKED = "Chat To Support Email Clicked",
  SALES_EMAIL_CLICKED = "Chat To Sales Email Clicked",
  GIFT_CARD_PRICE_CALCULATED = "Gift Card Price Calculated",
}

export class RudderStack {
  static appInstall(appType: "Android" | "IOS"): void {
    window.rudderanalytics.track(RudderEvents.APP_INSTALL, {
      app_type: appType,
    });
  }

  static contactFormSubmit(
      data:{
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string
      }
  ): void {
    window.rudderanalytics.track(RudderEvents.CONTACT_FORM_SUBMIT, {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      phone: data.phoneNumber,
    });
  }

  static phoneNumberClicked(): void {
    window.rudderanalytics.track(RudderEvents.PHONE_NUMBER_CLICKED);
  }

  static supportEmailClicked(): void {
    window.rudderanalytics.track(RudderEvents.SUPPORT_EMAIL_CLICKED);
  }

  static salesEmailClicked(): void {
    window.rudderanalytics.track(RudderEvents.SALES_EMAIL_CLICKED);
  }

  static giftCardPriceCalculated(options: {
    category: string;
    giftCard: string;
    currency: string;
    amount: number;
  }): void {
    window.rudderanalytics.track(RudderEvents.GIFT_CARD_PRICE_CALCULATED, {
      category: options.category,
      gift_card: options.giftCard,
      currency: options.currency,
      amount: options.amount,
    });
  }

}
